.container{
    display: flex;
    margin: 10%;
    justify-content: flex-start;
    gap: 47px;
    padding: 30px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.aboutContainer {
    display: flex;
    flex-direction: column;
    gap: 47px;
}

.description {
    font-size: 20px;
    line-height: 1.5; /* This sets the line height to 1.5 times the font size */ 
}

.title {
    font-size: 50px;
}

@media screen and (max-width:950px) {
    .container{
        flex-direction: column;
    }
}