.container {
  padding: 30px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin: 10%;
}
.title {
    margin-top: 50px;
    left: 50%;
    display: flex;
    align-items: center;
    font-size: 48px;
}
  
.skills {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--color-text);
    z-index: 1;
    gap: 30px;
    flex-wrap: wrap;
    margin-left: 10%;
    margin-bottom: 10px;
  }
  .centerdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px;
    border-radius: 20px;
    min-width: 200px;
    min-height: 200px;
    gap: 20px;
    transition: background-color 0.3s ease, color 0.3s ease; /* Add transition for smooth effect */
  }

  .card:hover {
    background-color: black;
    color: white;
}

.card:hover .imgclass {
    filter: invert(1); /* This inverts the colors, making a black logo white */
}

/* Media query for smaller screens (e.g., max-width: 768px) */
@media (max-width: 768px) {
  .container {
    padding: 20px;
    margin: 5%;
  }

  .title {
    font-size: 36px; /* Decrease font size for smaller screens */
    margin-top: 30px; /* Reduce margin */
  }

  .skills {
    margin-left: 5%; /* Adjust margin for smaller screens */
    gap: 20px; /* Reduce gap between skills */
  }

  .card {
    min-width: 150px; /* Reduce minimum width for smaller cards */
    min-height: 150px; /* Reduce minimum height */
  }
}

/* Additional media query for even smaller screens (e.g., max-width: 480px) */
@media (max-width: 480px) {
  .title {
    font-size: 24px; /* Further decrease font size */
  }

  .skills {
    flex-direction: column; /* Stack skills vertically */
    align-items: center; /* Center align skills */
  }

  .card {
    min-width: 100%; /* Full width for cards */
    min-height: 120px; /* Further reduce height */
  }
}
