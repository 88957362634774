@import "../../vars.css";

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 49px;
  z-index: 1;
  margin-left: 10%;
  margin-right: 10%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-text);
  z-index: 1;
}

.title {
  /* margin-bottom: 33px;
  font-family: var(--font-roboto); */
  position: absolute; /* Position the text absolutely */
  font-size: 72px;
  font-weight: 900;
  top: 50%; /* Center vertically */
  left: 25%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Centering trick */

 }

.description {
  /* font-size: 30px;
  font-family: var(--font-roboto);
  margin-bottom: 52px;
  position:absolute; */
  position: absolute; /* Position the text absolutely */
  top: 50%; /* Center vertically */
  left: 27%; /* Center horizontally */
  margin-top: 60px;
  transform: translate(-50%, -50%); /* Centering trick */
  font-size: 24px; /* Adjust font size */
  text-align: center; /* Center the text */
}

.heroImg {
margin-top: 60px;
  width: 60%;
  z-index: 1;

  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.topBlur {
  position: absolute;
  width: 50vw;
  height: 50vw;
  min-width: 350px;
  min-width: 350px;
  top: -128px;
  left: -10vw;
  border-radius: 764px;
  filter: blur(100px);
  z-index: 0;
}

.bottomBlur {
  position: absolute;
  width: 70vw;
  height: 50vw;
  min-width: 350px;
  min-width: 250px;
  top: 246px;
  right: -25vw;
  border-radius: 764px;
  filter: blur(100px);
  z-index: 0;
}

@media screen and (max-width: 860px) {
  .container {
    flex-direction: column-reverse;
  }

  .content {
    align-items: center;
    justify-content: space-between;
  }

  .title {
    font-size: 36px;
    font-weight: 900;
    margin: auto;
    font-family: var(--font-roboto);
    position: relative;
    left: 50%;
  }
  
  .description {
    font-size: 15px;
    font-family: var(--font-roboto);
    margin: auto;
    position: relative;
    left: 57%;
  }
  .heroImg {
    margin-bottom: 40px;
    z-index: -1;
  }
  
}