.container {
    color: #fff;
    background-color: #000;
    margin: 10%;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(51, 51, 51, 0.8); 
}

.content {
    margin: 2% 10%;
}

.experienceCardContainer {
    background-color: #1c1c1c;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.logoAndTitle {
    display: flex;
    align-items: center;
    gap: 20px;
}

.logo {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.title {
    font-size: 20px;
    margin: 0;
    color: #fff;
}

.dates {
    font-size: 14px;
    color: #bbb;
    margin-top: 10px;
}

.description {
    margin-top: 15px;
    color: #e0e0e0;
    line-height: 1.6;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}
.header {
    font-size: 50px;
    margin: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .experienceCardContainer {
        padding: 15px;
    }

    .logoAndTitle {
        flex-direction: column;
        text-align: center;
    }

    .title {
        margin-top: 10px;
    }

    .description {
        justify-content: center;
    }
}
