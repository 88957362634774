.contactSectionContainer {
    background-color: #fff;
    border-radius: 8px;
    padding: 40px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactTitle {
    font-size: 28px;
    margin-bottom: 30px;
    color: #000;
}

.contactContent {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    width: 100%;
}

.contactInfo {
    font-size: 16px;
    color: #000;
}

.contactInfo p {
    margin: 10px 0;
}

.contactForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.inputField {
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    color: #000;
    background-color: #f9f9f9;
    outline: none;
}

.textArea {
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    color: #000;
    background-color: #f9f9f9;
    outline: none;
    resize: none;
}

.submitButton {
    padding: 15px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submitButton:hover {
    background-color: gray;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .contactInfo {
        padding: 0 15px;
    }

    .infoItem {
        flex-direction: column;
        align-items: center;
    }
}